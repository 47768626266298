import request from '@/utils/request'
const baseURL = '/API/Visit'

export const GetList = function (data) {// 网站问题反馈
    return request({
        url: "/API/Visit/GetList",
        method: 'GET',
        params:data
    })
}
export const Del = function (data) {// 网站问题反馈
    return request({
        url: "/API/Visit/Del",
        method: 'POST',
        params:{ id:data },
        headers: {
            'Content-Type': 'application/json'
        },
    })
}