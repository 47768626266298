<template>
    <div class="model">
        <a-spin :spinning="spinning">
            <table class="table">
                <colgroup>
                    <col width='80'>
                    <col width='360'>
                    <col width='160'>
                    <col width='170'>
                </colgroup>
                <thead class="table-thead">
                    <tr>
                        <th>序号</th>
                        <th>标题</th>
                        <th>阅读时间</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr v-for="( item , index ) in data" :key="item.Id">
                        <td class="index"><span >{{index + 1}}</span></td>
                        <td>
                            <div class="tdtitle" @click="goDetail(item)">{{item.Name}}</div>
                        </td>
                        <td class="readTime">
                            {{item.CreateTime}}
                        </td>
                        <td class="handle">
                            <div class="handle_inner">
                                <span @click="goDetail(item)">继续阅读</span>
                                <a-popconfirm title="删除该记录？" ok-text="是" cancel-text="否" @confirm="deleteRecord(item)">
                                    <span>删除</span>
                                </a-popconfirm>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <a-empty v-if="data.length == 0"/>
        </a-spin>
        <Pagination
            :pageSize="params.pageSize"
            :total="total"
            @pageChange="pageChange"
            v-if="data.length>0"
            :current="params.pageIndex"
        />
    </div>
</template>
<script>
import Pagination from '~/components/common/Pagination'
import { GetList , Del } from '@/api/visit'
export default {
    props:['keyword'],
    components:{
        Pagination
    },
    data(){
        return{
            
            data:[],
            params:{
                pageIndex:1,
                pageSize:10,
                key:'',
                type:1
            },
            total:0,
            spinning:false,
        }
    },
    watch:{
        keyword:{
            handler(newVal,oldVal){
                this.params.key = newVal
            },
            immediate:true
        }
    },
    methods:{
        pageChange(page){
            this.params.pageIndex = page.page
            this.getList()
            document.getElementsByClassName('personal')[0].scrollIntoView({behavior: "auto"})
        },
        onSearch(){
            this.params.pageIndex = 1
            this.getList()
        },
        async getList(){
            this.spinning = true
            const { data , total } = await GetList(this.params)
            this.data = data 
            this.total = total
            this.spinning = false
        },
        goDetail(item){
            this.$router.push({ name:'card-info', params: { id:item.CardId } })
        },
        deleteRecord(item){
            this.spinning = true
            Del(item.Id).then(res => {
                this.$message.success('删除成功')
                this.spinning = false
                this.getList()
            }).catch(e => {
                this.spinning = false
            })
        }
    },
    mounted(){
        this.getList()
    }
}
</script>
<style lang="less" scoped>

.tdtitle{
    cursor: pointer;
    &:hover{
        color: #f6a75c;
    }
}

</style>